import gsap from 'gsap';

const useAnimatePopFromBottomText = (
    elementsToAnimate: HTMLElement[],
    triggerElement: HTMLElement | string,
    yTranslate: number,
    duration: number,
    animationStart?: string,
) => {
    const tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
            trigger: triggerElement,
            start: animationStart ?? 'top center',
        },
    });

    elementsToAnimate.map((item, index) => {
        const animatedItem = item.children[0];

        tl.fromTo(
            animatedItem,
            {
                y: yTranslate,
            },
            {
                y: 0,
                duration,
            },
            index === 0 ? '' : '=-0.65',
        );
    });
};

export default useAnimatePopFromBottomText;
