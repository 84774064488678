import React from 'react';
import Partner from './Partner/Partner';
import Key from '../../assets/images/key.svg';

import './Team.scss';

const Team = () => {
    const partners = [
        {
            id: 1,
            name: 'Mauro Cappelloni',
            position: 'Co-Founder',
            description:
                'Passionate developer and entrepreneur, with a knack for efficient and scalable Front-end applications with the latest technologies on the market. Learned to solve real business issues with technology from his 6+ years of experience working with big tech and industry companies (HP, and Estee Lauder Companies to name a few).',
            image: '/images/about/mauro.webp',
            linkedin: 'https://www.linkedin.com/in/mauro-cappelloni/',
        },
        {
            id: 2,
            name: 'Nicolas Pisani',
            position: 'Co-Founder',
            description:
                'Digital entrepreneur by heart. With more than 12 years of experience in business and product development, he connects the two worlds and helps the client grow their digital products. You’ll always find him on a call discussing new ideas with clients & team members.',
            image: '/images/about/nicolas.webp',
            linkedin: 'https://www.linkedin.com/in/nicolas-pisani',
        },
        {
            id: 3,
            name: 'Sebastian Belaustegui',
            position: 'Co-Founder',
            description:
                "There is not a project too big or too challenging for him, with more than 8 years of experience working as a Back-end engineer and obsessive attention to performance and security he is the one you'd want on your side if you want a world-class product.",
            image: '/images/about/sebastian.webp',
            linkedin: 'https://ar.linkedin.com/in/sebasbelaustegui',
        },
        {
            id: 4,
            name: 'Tomas Causa',
            position: 'Co-Founder',
            description:
                "He can't stand a boring template product, he's always pushing our attention to detail and product engagement to the next level, by leveraging his 9+ years of experience working for world-known brands he can make any crazy FrontEnd project happen. Do you want an elephant dancing on a hot tub built on CSS and JS? You got it!",
            image: '/images/about/tomas.jpg',
            linkedin: 'https://ar.linkedin.com/in/tomascausa',
        },
    ];

    return (
        <div className="team">
            <div className="team__background" aria-hidden="true"></div>
            <div className="team__wrapper">
                {partners.map(partner => {
                    let imageRight = false;
                    if (partner.id % 2 === 0) {
                        imageRight = true;
                    }

                    return (
                        <Partner
                            key={partner.id}
                            id={partner.id}
                            name={partner.name}
                            position={partner.position}
                            description={partner.description}
                            image={partner.image}
                            isImageRight={imageRight}
                            linkedin={partner.linkedin}
                        />
                    );
                })}
            </div>
            <img src={Key} className="team__key" alt="{+}" aria-hidden="true" />
        </div>
    );
};

export default Team;
