
const useParseString = () => {
    const parser = new DOMParser();

    const parse = (text: string) => {
        const parsedText = parser.parseFromString(text, 'text/html').body.textContent;
        return parsedText
    }

    return parse

}
export default useParseString