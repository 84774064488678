import React, { useContext, useEffect } from 'react';
import { AppContext, AppContextProps } from '../store/context';
import { ABOUTPAGE } from '../mock/SiteData';

import GenericHero from '../sections/GenericHero/GenericHero';
import AboutPhrase from '../sections/AboutPhrase/AboutPhrase';
import Team from '../components/Team/Team';
import SEO from '../components/SearchEngineOptimizations/SearchEngineOptimizations';

import 'normalize.css';

const { title, heroDescriptionText, imgSrc, imgAlt, bgImg, phraseDescriptionText, phraseText } =
    ABOUTPAGE;

const AboutPage = () => {
    const { setCurrentPage } = useContext(AppContext) as AppContextProps;

    useEffect(() => {
        setCurrentPage('About');
    }, []);

    return (
        <main className="page page--about">
            <GenericHero
                descriptionText={heroDescriptionText}
                imgAlt={imgAlt}
                imgSrc={imgSrc}
                title={title}
                bgImg={bgImg}
                paralaxTranslateY={[20, -20]}
            />
            <AboutPhrase text={phraseText} description={phraseDescriptionText} />
            <Team />
        </main>
    );
};

export default AboutPage;

export const Head = () => (
    <SEO title="Loop3 Studio - About Us" description={`${heroDescriptionText}`} />
);
